.field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
  position: relative;
  justify-content: space-around;
}

.spinner {
  width: 35px;
  height: 35px;
  border: 4px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}
.loaderContainer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: fixed;
  z-index: 1;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.password {
  background-color: #ECEAEA !important;
  border-style: solid !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: 2px solid #eceaea;
  transition: 0.5s;
  outline: none;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-between;
}

.password:focus-within {
  border: 2px solid rgb(135, 184, 254);
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}


.warnPassword {
  align-items: unset !important;
  background-color: #ffd6d6;
}

.warnKey {
  padding: 0.1em;
  margin-top: 0.8em;
  border-radius: 8px;
  margin-bottom: -1em;
  padding-left: 0.4em;
  display: flex;
  align-items: baseline;
}

.warnError {
  white-space: pre-wrap;
  padding-top: 0.1em;
}

input::-ms-reveal {
  display: none;
}

.register {
  display: flex;
  justify-content: center;
  margin-top: 1em !important;

  @media screen and (max-width: 810px) {
    padding: 0;
  }
}

.fieldError {
  border-color: #f94646 !important;
}

.upperCase::first-letter {
  text-transform: uppercase;
}