.AppStyle {

  font-family: 'Euclid Circular A' !important;

  @media screen and (min-width:1280px) {
    justify-content: start;
    align-content: start;
  }

  @media screen and (max-width:767px) {
    height: 600px !important;
  }

  @media screen and (min-width: 1650px) {
    height: 800px !important;
  }

}

.line {
  margin: 0.5em 0em !important;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 1em;
  padding: 0.75em 1em;
  border-radius: 8px;
}

.row {
  margin-top: 0.2em;
  margin-bottom: 1em;
}

.imgLogo {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  //position: absolute;
  border-radius: 20px;
  //width: inherit;
  height: inherit;
  object-fit: contain;
  height: 8em;
  margin-top: 2em;
}

.formLogin {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 560px;

  @media screen and (min-width: 950px) {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  @media screen and (min-width: 1650px) {
    min-height: 100%;
    font-size: 1.5em !important;
  }
}

.input {
  // background-color: #ECEAEA !important;
  background-color: #eceaea96 !important;
  border-style: solid !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: 2px solid #eceaea;
  transition: 0.5s;
  outline: none;
  // padding: 0.375rem 0.75rem;
  padding: 0.2rem 0.5rem;
  display: flex;
  justify-content: space-between;
  height: 50%;
}

.input:focus-within {
  border: 2px solid rgb(135, 184, 254);
}

.fieldError {
  border-color: #f94646 !important;
}

.register {
  display: flex;
  justify-content: center;
  margin-top: 1em;

  @media screen and (max-width: 810px) {
    padding: 0;
  }
}

.input:focus {
  border: 2px solid #00b3e583;
}

.inputWidth {
  width: 100%;
}

.imgUser {
  height: 170px;
  width: 170px;
}

.hoverImgUser {
  cursor: pointer;
  height: 170px;
  width: 170px;
  border-radius: 100%;
}

.hoverProfileUser {
  width: max-content;
  padding: 4px;
  cursor: pointer;
  border-radius: 100%;
  contain: content;
}

.hoverProfileUser:hover {
  background: rgba(12, 11, 11, 0.44);
  border-radius: 100%;
  opacity: 0.5;

  .iconLogoHover {
    display: block !important;
    inset: 28% 38% 50%;
  }
}

// .hoverImgUser:hover {
//   background-color: #000 !important;
//   border-radius: 100%;
//   opacity: 0.8;
// }

.signImg {
  cursor: pointer;
  width: 420px;
  height: 120px;
  border-radius: 8px;
}

.hoverSignUser {
  width: max-content;
  padding: 5px;
  cursor: pointer;
  border-radius: 8px;
  contain: content;
  display: flex;
  justify-content: center;
}

.hoverSignUser:hover {
  background: rgba(12, 11, 11, 0.44);
  opacity: 0.5;

  .iconLogoHover {
    display: block !important;
  }
}

.iconLogoHover {
  position: absolute;
  // top: 28%;
  // left: 38%;
  // right: 50%;
  inset: 20% 44%;
  color: #fff;
  font-size: 43px;
  max-width: 0px;
  display: none;
}

.inputPhotoHover {
  // position: absolute;
  // margin-top: 15px;
  width: 52%;
  // margin-left: 40px;
  font-size: 80px;
  opacity: 0;
  display: block;
  height: 170px;
  border-radius: 100%;
  width: 170px;
}

.signInput {
  width: 52%;
  font-size: 80px;
  opacity: 0;
  display: block;
  height: 170px;
  border-radius: 100%;
  width: 170px;
}

.imgSignature {
  width: 400px;
  height: 80px;
}

.hoverImgSignature {
  height: 80px;
  width: 400px;
  background: rgba(12, 11, 11, 0.44);
  border-radius: 8px;
  position: absolute;
  opacity: 0;
}

.hoverImgSignature:hover {
  background-color: #000 !important;
  opacity: 0.8;
}

.inputSignatureHover {
  width: 52%;
  font-size: 80px;
  opacity: 0;
  display: block;
  height: 80px;
  border-radius: 25%;
  width: 400px;
}

.signatureLogoHover {
  position: absolute;
  top: 5%;
  left: 45%;
  color: #fff;
  font-size: 43px;
  max-width: 0px;
}


.buttonRemove {
  align-self: center;
  background-color: #EF4F4F;
  border-radius: 8px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 6px;
  width: 150px;
  margin-bottom: 1em;

  &:active {
    background-color: #ab3939;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) inset;
  }
}

@media screen and (min-width: 1280px) {
  .button {
    width: 270px;
  }
}

.spinner {
  width: 35px;
  height: 35px;
  border: 4px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.spinnerPage {
  width: 50px;
  height: 50px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
  margin-bottom: 10em;
}

.loadSpinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.303);
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderContainer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: fixed;
  z-index: 1;
}

.logoutButton {
  width: 100px;
  margin-top: -3.5em;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
  margin-right: 1em;
  background-color: #d03a3a;
  height: 2em;
  border-radius: 8px;
  color: #fff;
}

.modalError {
  background: #ef4f4f !important;
}

.modalSuccess {
  background: #87d7ac !important;
}

.modalWrapper {
  animation: fadein .5s;

  transition: opacity 3s ease-in-out;
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  // animation: fadeout .5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}