.input {
  background-color: #eceaea96 !important;
  border-style: solid !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: 2px solid #eceaea;
  transition: 0.5s;
  outline: none;
  padding: 0.2rem 0.5rem;
  display: flex;
  justify-content: space-between;
  height: 35px;
}

.input:focus-within {
  border: 2px solid rgb(135, 184, 254);
}

.fieldError {
  border-color: #f94646 !important;
}
