.botao {
  align-self: center;
  background-color: #005ca8;
  border-radius: 8px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 8px 36px;

  width: max-content;

  &:enabled:active {
    background-color: #014881;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) inset;
  }

  &:disabled {
    background-color: #01478191;
  }
}

.primary-color {
  background-color: #005ca8;
  &:enabled:active {
    background-color: #014881;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) inset;
  }

  &:disabled {
    background-color: #01478191;
  }
}

.success-color {
  background-color: #1dd1a1;
  &:enabled:active {
    background-color: #10ac84;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) inset;
  }

  &:disabled {
    background-color: #10ac8491;
  }
}

.flat-primary-color {
  box-shadow: none!important;
  background: transparent;
  color: #005ca8;
  &:enabled:active {
    background: transparent;
    color: #014881;
  }

  &:disabled {
    background: transparent;
    color: #01478191;
  }
}

.flat-warn-color {
  box-shadow: none!important;
  background: transparent;

  color: #EF4F4F;
  &:enabled:active {
    background: transparent;
    color: #b30000;
  }

  &:disabled {
    background: transparent;
    color: #b3000091;
  }
}

.flat-success-color {
  box-shadow: none!important;
  background: transparent;

  color: #1dd1a1;
  &:enabled:active {
    background: transparent;
    color: #10ac84;
  }

  &:disabled {
    background: transparent;
    color: #10ac8491;
  }
}

.warn-color {
  background-color: #EF4F4F;
  &:enabled:active {
    background-color: #b30000;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) inset;
  }

  &:disabled {
    background-color: #b3000091;
  }
}

.smallButton {
  padding: 4px 8px;
}